import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/core/services/core.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bs-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.scss']
})
export class ProfilesComponent implements OnInit {

  public form: FormGroup = new FormGroup({});
  public currentTeams = [];
  public currentProfiles = [];
  public loading = true;
  public title = '';

  constructor(
    private route: ActivatedRoute,
    private coreService: CoreService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.currentTeams = data.type.split(',');
      this.getProfileByTeam(this.currentTeams);
    });
  }

  getProfileByTeam(teams: any[]) {
    this.loading = true;
    this.title = [teams.slice(0, -1).join(', '), teams.slice(-1)[0]]
                    .join(teams.length < 2 ? '' : ' & ').replace('-', ' ');
    this.coreService.fetchData('profile?_limit=1000').pipe(
      map((profiles: any) => {
        const validProfiles = [];

        profiles.forEach((profile) => {
          profile.teams.forEach((team) => {
            if (teams.includes(team.handle)) {
              validProfiles.push(profile);
            }
          });
        });

        return validProfiles;
      })
    ).subscribe((profiles) => {
      this.currentProfiles = profiles;
      this.loading = false;
    });
  }

}
