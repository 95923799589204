import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreService } from '../../../core/services/core.service';
import { Router } from '@angular/router';

@Component({
  selector: 'bs-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public showSubNavigation = false;
  public showMobileNavigation = false;
  public currentNavigation;
  public currentSubNavigation = [];
  private hidingSubNavigationTimeout: any;

  constructor(
    public coreService: CoreService,
    public router: Router,
    ) { }

  ngOnInit() {
  }

  public logout() {
    window.localStorage.removeItem('jwt');
    this.router.navigate(['/']);
  }

  public topMenuEnter(navigation) {
    if (navigation.subnavs && navigation.subnavs.length > 0) {
      this.currentNavigation = navigation;
      this.currentSubNavigation = navigation.subnavs.sort(this.coreService.comparePositions);
      this.showSubNav();
    }
  }

  public showSubNav() {
    clearTimeout(this.hidingSubNavigationTimeout);
    this.showSubNavigation = true;
  }

  public hideSubNav(timeoutLength = 200) {
    this.hidingSubNavigationTimeout = setTimeout((a => {
      this.showSubNavigation = false;
    }), timeoutLength);
  }

  public toggleMobileMenu() {
    this.showMobileNavigation = !this.showMobileNavigation;
  }

  public goToAthletePortal() {
    window.open('https://britishshooting.sport80.com/login', '_blank');
  }

}
