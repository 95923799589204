import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreService } from 'src/app/core/services/core.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bs-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  public profile: any;
  public carouselData: any = {items: []};

  constructor(
    private http: HttpClient,
    public coreService: CoreService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
      this.route.params.subscribe(
        params => {
          this.coreService
          .fetchData('profile/' + this.route.snapshot.params['id'])
          .subscribe((results: any) => {
            if (results.backgroundImage) {
              this.carouselData.items = [{
                image: results.backgroundImage.url
              }];
            }
            results.biography = this.coreService.generateBody(results.biography);
            this.profile = results;
          });
        }
      );
  }

}
