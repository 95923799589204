import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ArticleComponent } from './template/containers/article/article.component';
import { HomeComponent } from './template/containers/home/home.component';
import { SearchComponent } from './template/containers/search/search.component';
import { LoginComponent } from './template/containers/login/login.component';
import { ForgotPasswordComponent } from './template/containers/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './template/containers/reset-password/reset-password.component';
import { RegisterComponent } from './template/containers/register/register.component';
import { NewsComponent } from './template/containers/news/news.component';
import { EventsComponent } from './template/containers/events/events.component';
import { ProfilesComponent } from './template/containers/profiles/profiles.component';
import { ProfileComponent } from './template/containers/profile/profile.component';
import { ContactComponent } from './template/containers/contact/contact.component';
import { ClubsComponent } from './template/containers/clubs/clubs.component';
import { PageComponent } from './template/containers/page/page.component';
import { ErrorComponent } from './template/containers/error/error.component';
import { AccountComponent } from './template/containers/account/account.component';
import { PartnersComponent } from './template/containers/partners/partners.component';

const appRoutes: Routes = [
    {
      path: '',
      pathMatch: 'full',
      component: HomeComponent,
    },
    {
      path: 'search',
      component: SearchComponent,
    },
    {
      path: 'search/:term',
      component: SearchComponent,
    },
    {
      path: 'account',
      component: AccountComponent,
    },
    {
      path: 'login',
      component: LoginComponent,
    },
    {
      path: 'forgot-password',
      component: ForgotPasswordComponent,
    },
    {
      path: 'reset-password/:passwordResetHash',
      component: ResetPasswordComponent,
    },
    {
      path: 'register',
      component: RegisterComponent,
    },
    {
      path: 'news',
      component: NewsComponent,
    },
    {
      path: 'events',
      component: EventsComponent,
    },
    {
      path: 'article/:id/:handle',
      component: ArticleComponent,
    },
    {
      path: 'article/:id',
      component: ArticleComponent,
    },
    {
      path: 'profiles/:type',
      component: ProfilesComponent,
    },
    {
      path: 'profile/:id',
      component: ProfileComponent,
    },
    {
      path: 'profile/:id/:name',
      component: ProfileComponent,
    },
    {
      path: 'contact',
      component: ContactComponent,
    },
    {
      path: 'contact/:type',
      component: ContactComponent,
    },
    {
      path: 'clubs',
      component: ClubsComponent,
    },
    {
      path: 'partners',
      component: PartnersComponent,
    },
    {
      path: 'partners/:type',
      component: PartnersComponent,
    },
    {
      path: 'page/:handle',
      component: PageComponent,
    },
    {
      path: 'error/not-found',
      data: { breadcrumb: 'Not Found' },
      component: ErrorComponent,
    },
    {
      path: '**',
      redirectTo: '/error/not-found',
    },
  ];

  const routingOptions: ExtraOptions = {
    enableTracing: false,
    scrollPositionRestoration: 'enabled',
  };

  export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(appRoutes, routingOptions);
