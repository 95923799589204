import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/core/services/core.service';

interface SearchResponse {
  id: number;
  image: string;
  title: string;
  subtitle: string;
}

@Component({
  selector: 'bs-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public form: FormGroup = new FormGroup({});
  public results: SearchResponse[];
  public page = 0;
  public limit = 10;
  public showLoadMore = false;

  constructor(
    private http: HttpClient,
    public coreService: CoreService,
  ) {
    this.form = new FormGroup({
      type: new FormControl('article', [Validators.required]),
      query: new FormControl('', [Validators.required]),
      start: new FormControl(0, [Validators.required]),
      limit: new FormControl((this.limit + 1), [Validators.required]),
    });
  }

  ngOnInit() {
  }

  public action() {
    this.results = [];
    this.page = 0;
    this.showLoadMore = true;
    this.form.get('start').setValue((this.page * this.limit));
    this.form.updateValueAndValidity();
    this.search();
  }

  public loadMore() {
      this.page += 1;
      this.form.get('start').setValue((this.page * this.limit));
      this.form.updateValueAndValidity();
      this.search();
  }

  public search() {
    this.http
    .post(`${this.coreService.apiUrl}search`, this.form.value)
    .subscribe((results: any) => {
      this.results = this.results.concat(results.slice(0, this.limit));
      results.length === (this.limit + 1) ? this.showLoadMore = true : this.showLoadMore = false;
    });
    window.scrollTo(0, 500);
  }

}
