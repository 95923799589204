import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'bs-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public form: FormGroup = new FormGroup({});
  public recaptureSiteKey = '6LfuBXgUAAAAAJU4fO42nzf3322q8KvslGm3-p-O';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public http: HttpClient,
    public snackBar: MatSnackBar,
    private coreService: CoreService,
  ) { }

  public ngOnInit() {
    this.form = new FormGroup({
      recaptcha: new FormControl('', [Validators.required]),
      type: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      telephone: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      body: new FormControl('', [Validators.required]),
    });

    if (this.route.snapshot.params['type'] !== '') {
      this.form.get('type').setValue(this.route.snapshot.params['type']);
      this.form.updateValueAndValidity();
    }
  }

  public action() {
    this.http.post(`${this.coreService.apiUrl}contact`, this.form.value)
      .subscribe(
        (res: any) => {
          this.snackBar.open('Your message has been successfully sent.', 'Ok');
        },
        (res) => {
          this.snackBar.open(res.error.message, 'Ok');
        });
  }

  public loginUser(jwtToken: string) {
    window.localStorage.setItem('jwt', jwtToken);
    this.router.navigate(['/']);
  }

  public handleCorrectCaptcha(event) {
    this.form.get('recaptcha').setValue(event);
    this.form.updateValueAndValidity();
  }

}
