import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'bs-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public tweets;

  constructor(
    private http: HttpClient,
    public coreService: CoreService,
  ) { }

  ngOnInit() { }

}
