import { Component, OnInit } from '@angular/core';
import { CoreService } from '../../../core/services/core.service';

@Component({
  selector: 'bs-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  thisYear = new Date().getUTCFullYear();

  constructor(
    public coreService: CoreService,
  ) { }

  ngOnInit() {
  }

}
