import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'web';

  constructor(
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    this.angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit() { }

}
