import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'bs-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() size: 'small' | 'large' = 'small';

  @Input() type: 'article' | 'link' | 'video' | 'page' |
   'event' | 'profile' | 'partner' | 'club';

  @Input() data: any;

  public sanitisedVideoLink: SafeResourceUrl;

  constructor(
    public sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    if (this.data && this.data.video) {
      this.sanitisedVideoLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video);
    }

  }

}
