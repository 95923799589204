import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'bs-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  public currentArticles = [];
  private startArticles = 0;
  public showMoreButtonArticles = true;

  public currentBlogs = [];
  private startBlogs = 0;
  public showMoreButtonBlogs = true;
  public unsubscribe$: Subject<any> = new Subject();

  constructor(
    public coreService: CoreService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {

    this.route.queryParams
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(params => {
        if (params['d']) {
          this.router.navigate([`/article/${params['d']}`]);
        }
      });

      this.showMoreButtonArticles = true;
      this.showMoreButtonBlogs = true;
      this.startArticles = 0;
      this.startBlogs = 0;
      this.loadArticles(this.startArticles);
      this.loadBlogs(this.startArticles);
  }

  public loadMoreArticles() {
    this.startArticles += 16;
    this.loadArticles(this.startArticles);
  }

  public loadMoreBlogs() {
    this.startBlogs += 16;
    this.loadBlogs(this.startBlogs);
  }

  public goTo(url) {
    document.location = url;
  }

  private loadArticles(start: number) {
    this.coreService.fetchData(`article?type_ne=blog-post&_sort=publishedDateTime:DESC&_start=${start}&_limit=16`)
    .subscribe((data) => {
      if(data.length === 0) {
        this.showMoreButtonArticles = false;
      }
      this.currentArticles = this.currentArticles.concat(data);
    });
  }

  private loadBlogs(start: number) {
    this.coreService.fetchData(`article?type=blog-post&_sort=publishedDateTime:DESC&_start=${start}&_limit=16`)
    .subscribe((data) => {
      if(data.length === 0) {
        this.showMoreButtonBlogs = false;
      }
      this.currentBlogs = this.currentBlogs.concat(data);
    });
  }

}
