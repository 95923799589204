import { Component, OnInit, Input, OnChanges } from '@angular/core';

interface CarouselData {
  type: string;
  images?: any[];
  items?: CarouselItem[];
}

interface CarouselItem {
  image: string;
  title: string;
  subTitle: string;
  link: string;
}

@Component({
  selector: 'bs-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {

  @Input() data: CarouselData;

  public currentMargin = 0;
  public currentPosition = 0;
  
  constructor() {}

  public hasNext() {
    return this.currentPosition < (this.data.items.length - 1);
  }

  public hasPrevious() {
    return this.currentPosition > 0;
  }

  public next() {
    this.currentPosition++;
    this.currentMargin = this.currentPosition * 100;
  }

  public previous() {
    this.currentPosition--;
    this.currentMargin = this.currentPosition * 100;
  }

}
