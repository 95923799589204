import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CoreService } from 'src/app/core/services/core.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bs-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})
export class ArticleComponent implements OnInit {

  public articleData: any;
  public article: any;
  public carouselData: any = {items: []};

  constructor(
    private http: HttpClient,
    public coreService: CoreService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
      this.activatedRoute.params.subscribe(
        params => {
          this.coreService
          .fetchData(`article/${params['id']}`)
          .subscribe((article: any) => {
            if (article.image) {
              this.carouselData.items = [{
                image: article.image.url
              }];
            }
            this.articleData = article;
            this.article = this.coreService.generateBody(article.body);
          });
        }
      );
  }

}
