import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';

@Component({
  selector: 'bs-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public carouselData = {
    items: []
  };

  public currentDateTime = new Date().toISOString();

  constructor(
    public coreService: CoreService,
  ) {}

  ngOnInit() {
    this.coreService.fetchData('article?headlineArticle=true&_sort=position:ASC', true).subscribe((data) => {
      this.carouselData.items = [];
      data.forEach((item: any) => {
        this.carouselData.items.push({
          image: item.image.url,
          title: item.title,
          subTitle: item.subTitle,
          link: `/article/${item.id}`,
        });
      });
    });
  }

}
