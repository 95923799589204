import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/core/services/core.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'bs-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  public page = {
    title: '',
    body: this.coreService.generateBody(''),
  };
  public pageData: any;
  public carouselData: any = {items: []};

  constructor(
    public coreService: CoreService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      params => {
        this.coreService
        .fetchData('page?handle=' + this.route.snapshot.params['handle'])
        .subscribe((data: any) => {
          if (data.length > 0) {
            if (data[0].image) {
              this.carouselData.items = [{
                image: data[0].image.url
              }];
            }
            this.pageData = data[0];
            this.page.body = this.coreService.generateBody(data[0].body);
            this.page.title = data[0].title;
          }
        });
      }
    );
  }

}
